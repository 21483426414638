import React, { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import { bp, colors, fonts } from "./Styles"
import Modal from "./Modal"
import ContactForm, { ThanksModal } from "./modals/ContactForm"
import useIntersectionObserver from "../util/use-intersection-observer"

const KontaktFooter: React.FC<{
  href?: string
  title?: string
  shouldAppear?: boolean
  onClick?: (e: React.MouseEvent<any, MouseEvent>) => void
  className?: string
}> = ({ onClick, href, className, children, title }) => {
  return (
    <>
      <KontaktLink onClick={e => onClick?.(e)} className={className}>
        {title || "Kontakt"}
      </KontaktLink>
    </>
  )
}

export const KontaktFooterInner: React.FC<{
  hideExtras?: boolean
  title?: string
}> = ({ hideExtras = false, title }) => {
  const [showModal, setshowModal] = useState(false)
  const fromElement = useRef<HTMLElement | null>(null)
  const [showCTA, setshowCTA] = useState(false)
  const containerMRef = useRef<HTMLDivElement>(null)
  const entry = useIntersectionObserver(containerMRef, {
    freezeOnceVisible: true,
    threshold: 0.9,
  })

  useEffect(() => {
    if (!entry?.isIntersecting) return
    setshowCTA(true)
  }, [entry])

  const [wasSent, setWasSent] = useState(false)

  return (
    <KontaktFooterInnerContainer
      {...{ hideExtras, showCTA }}
      ref={containerMRef}
    >
      <KontaktFooter
        onClick={e => {
          fromElement.current = e.target as HTMLElement
          setshowModal(true)
        }}
        className={`${showModal ? "active" : ""}`}
        title={title || "Kontakt"}
      ></KontaktFooter>

      <Modal
        onClose={() => setshowModal(false)}
        isShown={showModal}
        showBackdrop
        fromElement={fromElement}
      >
        <ContactForm
          onClose={() => setshowModal(false)}
          onSent={() => {
            setshowModal(false)
            setWasSent(true)
          }}
        />
      </Modal>

      <ThanksModal isShown={wasSent} onClose={() => setWasSent(false)} />
    </KontaktFooterInnerContainer>
  )
}

const KontaktFooterInnerContainer = styled.div`
  display: inline-block;
`

const KontaktLink = styled.p`
  cursor: pointer;
`
export default KontaktFooter
