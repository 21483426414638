import React, { FormEvent, useState } from "react"
import styled from "styled-components"
import LogoSVG from "../images/icons/G51_Logo.svg"
import SubmitInput from "./SubmitInput"
import { colors, fonts, bp } from "./Styles"
import Checkbox from "./Checkbox"
import FadeLink from "./FadeLink"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Modal from "./Modal"
import TextAlert from "./modals/TextAlert"
import { event } from "../util/ssr"
import { KontaktFooterInner } from "./KontaktFooter"
import { AnchorLink } from "gatsby-plugin-anchor-links"

interface FooterProps {
  className?: string
}

const Footer: React.FC<FooterProps> = FooterProps => {
  const { t } = useTranslation()

  return (
    <Container className={FooterProps.className}>
      <NewsletterColumn t={(key: string) => t(key)} />

      <LinksColumn
        title={t("G51 Solutions")}
        contact={true}
        t={(key: string) => t(key)}
        links={[
          [`${t("Impressum")}`, "/legalnotice"],
          [`${t("Datenschutz")}`, "/privacy"],
        ]}
      />

      <LinksColumn
        title={t("Ansatz")}
        links={[
          [`${t("Lösungen schaffen")}`, "/ansatz/#create-solutions"],
          [`${t("Probleme verstehen")}`, "/ansatz/#problem-understanding"],
          [`${t("Unser Service")}`, "/ansatz/#design"],
          [`${t("Skalierung")}`, "/ansatz/#scaling"],
          [`${t("Prozesse")}`, "/ansatz/#process"],
        ]}
      />

      <LinksColumn
        title={t("Expertise")}
        links={[
          [`${t("Technologien")}`, "/expertise/#Technologien"],
          [`${t("Moderne Architektur")}`, "/expertise/#modern-architecture"],
          [`${t("Integration und DevOps")}`, "/expertise/#integration"],
          [`${t("Sicherheit & Datenschutz")}`, "/expertise/#Sicherheit"],
          [`${t("DSGVO")}`, "/expertise/#Komplettbetreuung"],
        ]}
      />

      <LinksColumn
        title={t("Cases")}
        links={[
          ["qprem", "/cases/#Qprem"],
          ["expopartner", "/cases/#Expopartner"],
          [`${t("Referenzen")}`, "/cases/"],
        ]}
      />

      <ContactColumn t={(key: string) => t(key)} />
      <LegalNotice t={(key: string) => t(key)} />
    </Container>
  )
}

const NewsletterColumn: React.FC<{ t: (key: string) => string }> = ({ t }) => {
  const [wasSent, setWasSent] = useState(false)
  const [accept, setAccept] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState("")

  const [hasCheckError, setHasCheckError] = useState(false)
  const [hasEmailError, setHasEmailError] = useState(false)

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const val = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const validEmail = val.test(email)

    if (!validEmail || !accept) {
      event("newsletter_subscribe_fail", {
        form: "footer",
        valid_email: validEmail ? "yes" : "no",
        privacy_notice_accepted: accept ? "yes" : "no",
      })

      setHasEmailError(!validEmail)
      setHasCheckError(!accept)
      return
    }

    setIsLoading(true)
    const request = { email }
    event("newsletter_subscribe", { form: "footer" })

    try {
      await fetch("https://microsite-api.g51.dev/newsletter", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(request),
      })

      setIsLoading(false)
      setWasSent(true)
    } catch (e) {
      console.dir(e)
      setIsLoading(false)
      setWasSent(true)
    }
  }

  return (
    <NewsletterContainer>
      <div className="for-you">
        <LogoSVG className="logo" />
        <span>
          {t("Digitale Lösungen")}
          <br />
          {t("für Unternehmen")}
        </span>
      </div>

      <div className="register">
        <p>
          {t(
            "Erhalten Sie Aktuelles rund um Technology, Innovation & digitale Software Lösungen"
          )}
        </p>
        <form onSubmit={e => onSubmit(e)} noValidate>
          <SubmitInput
            placeholder={t("E-Mail Eingeben")}
            className="email"
            ariaLabel="subscribe to newsletter"
            disabled={isLoading}
            onChange={s => {
              setEmail(s.target.value)
              setHasEmailError(false)
            }}
          />
          <Checkbox
            variant="primary"
            checked={accept}
            onChange={c => {
              setAccept(c)
              setHasCheckError(false)
            }}
            disabled={isLoading}
          >
            <span className="text">
              {t("Ich akzeptiere die")}{" "}
              <FadeLink href="/privacy">{t("Datenschutzerklärung")}</FadeLink>
            </span>
          </Checkbox>

          {(hasEmailError || hasCheckError) && (
            <div className="errors">
              {hasCheckError && (
                <p className="error error-check">
                  {t("Bitte akzeptieren Sie die")}{" "}
                  <strong>{t("Datenschutzerklärung")}</strong>.
                </p>
              )}

              {hasEmailError && (
                <p className="error error-mail">
                  {t("Bitte geben Sie eine gültige")}{" "}
                  <strong>{t("E-Mail-Adresse")}</strong> {t("an.")}
                </p>
              )}
            </div>
          )}
        </form>

        <Modal onClose={() => setWasSent(false)} isShown={wasSent} showBackdrop>
          <TextAlert
            onClose={() => setWasSent(false)}
            title={t("Vielen Dank!")}
          >
            <p style={{ marginBottom: "0.4em" }}>
              {t("Sie wurden für den Newsletter angemeldet.")}
            </p>
          </TextAlert>
        </Modal>
      </div>
    </NewsletterContainer>
  )
}

const LinksColumn: React.FC<{
  title: string
  links: [string, string][]
  contact?: boolean
  t?: (key: string) => string
}> = ({ title, links, contact, t }) => (
  <LinksContainer>
    <h4>{title}</h4>

    <ul>
      {contact && (
        <li>
          <KontaktFooterInner title={t!("Kontakt")} />{" "}
        </li>
      )}
      {links.map(([text, href], idx) => (
        <li key={idx}>
          <AnchorLink to={href} title={text}>
            {text}
          </AnchorLink>
        </li>
      ))}
    </ul>
  </LinksContainer>
)

const ContactColumn: React.FC<{ t: (val: string) => string }> = ({ t }) => (
  <ContactContainer>
    <div className="call-us">
      <h2>{t("Haben Sie Fragen?")}</h2>
      <p>
        <a href="tel:+496934876566">Tel. +49 (0) 69 348 765 66</a>
      </p>
    </div>

    <div className="hours">
      <h2>{t("Unsere Sprechzeiten")}</h2>
      <p>{t("Mo–Fr 09:00–12:00 Uhr")}</p>
      <p>{t("Mo–Do 13:00–18:00 Uhr")}</p>
    </div>
  </ContactContainer>
)

const LegalNotice: React.FC<{ t: (val: string) => string }> = ({ t }) => (
  <LegalNoticeContainer>
    <FadeLink href="/legalnotice">{t("Impressum")}</FadeLink>
    <FadeLink href="/privacy">{t("Datenschutz")}</FadeLink>
  </LegalNoticeContainer>
)

const LinksContainer = styled.div`
  h4 {
    padding: 1.3rem 0.6rem;

    font-size: 0.9rem;
    font-family: ${fonts.bold};
    text-transform: uppercase;
  }

  ul {
    padding: 1rem 0.5rem;
  }

  li {
    font-size: 0.9rem;
    margin-bottom: 2.2rem;

    > a {
      position: relative;
      ::after {
        content: "";
        position: absolute;
        top: -5px;
        left: -5px;
        right: -5px;
        bottom: -5px;
      }
    }
  }

  ${bp("mobile")`
    order: 3;

    h1 {
      font-size: 1.4rem;
      padding: 1.7rem 0;
    }

    ul {
      display: none;
    }
    h4 {
      font-size:1.2rem;
      padding-left: 0;
    }
  `}

  ${bp("ipad")`
    order: 3;

    h1 {
      font-size: 1.4rem;
      padding: 1.7rem 0;
    }

    ul {
      display: block;
      padding: 1rem 0rem;
    }
    h4 {
      font-size:1.2rem;
      padding-left: 0;
    }
  `}
`

const NewsletterContainer = styled.div`
  span.text {
    display: inline-block;
    height: 17px;
    line-height: 17px;
  }

  p.error {
    margin-bottom: 0;
    color: #de1616;

    strong {
      color: #de1616;
    }
  }

  div.errors {
    margin-top: 0.1rem;
    font-size: 0.78rem;
  }

  .for-you {
    display: flex;
    padding: 0.6rem 0.25rem;
    font-size: 1.21rem;
    line-height: 1.85;
    align-items: flex-start;

    > span {
      padding-left: 1.35rem;
    }

    .logo {
      flex-shrink: 0;
      width: 3.4rem;
      transform: translate3d(0, 2px, 0);
    }

    ${bp("ldpi")`
      font-size: 1rem;
      padding-right: 2rem;

      .logo {
        width: 2.9rem;
      }
    `}

    ${bp("mobile")`
      font-size: 1.6rem;
      padding-right: 6rem;
      padding-left: 0;

      .logo {
        width: 5.6rem;
        transform: translate3d(0, 6px, 0);
        margin-right: 1rem
      }
    `}
  }

  .register {
    font-size: 0.87rem;
    line-height: 1.82;
    padding: 0.75rem 2.5rem 0.75rem 0.5rem;

    .email {
      margin: 2rem 0 1.7rem;
      width: 16.25rem;
    }

    ${bp("mobile")`
    padding-left: 0;
  `}
  }

  ${bp("mobile")`
    order: 1;

    h2{
      max-width: 100%;
      width: 22rem;
    }

    .register {
      p {
        max-width: 100%;
        width: 26rem;
        font-size: 1.4em;
        line-height: 1.5;
      }

      .email {
        height: 6rem;
        max-width: 100%;
        width: 21rem;
      }

      .text {
        vertical-align: sub;
        font-size: 1.2rem;
        padding-left: .5rem;
      }
    }
  `}

  ${bp("mobile")`
    grid-column: 1 / 3;
  `}
`

const LegalNoticeContainer = styled.div`
  ${bp("mobile")`
    order: 5;
    font-size: 1.4rem;
    padding-top: 3.3rem;

    > :first-child {
      padding-right: 3rem;
      border-right: 1px solid ${colors.background};
    }

    > :last-child {
      padding-left: 3rem;
      border-left: 1px solid ${colors.background};
    }
  `}
`

const Container = styled.footer`
  position: relative;
  height: 29.5em;
  padding: 5rem 0;
  display: flex;
  width: 103rem;
  max-width: calc(100% - 12rem);
  margin: 0 auto;
  font-family: ${fonts.medium};

  > div {
    flex: 1;

    &${NewsletterContainer} {
      flex: 1.5;
    }
  }

  > ${LegalNoticeContainer} {
    display: none;
  }

  ${bp("mobile")`
    flex-direction: column;
    height: auto;
    padding-top: 4rem;
    max-width: calc(100% - 10rem);
  
    > ${LegalNoticeContainer} {
      display: flex;

    }
      ${bp("mobile_mdpi")`
      >${LinksContainer} {
        display: none;
      }
      `}
  `}

  ${bp("ipad")`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 6em;
  
    > ${LegalNoticeContainer} {
      display: none;
    }
  `}
`

const ContactContainer = styled.div`
  max-width: 100%;

  > .call-us {
    max-width: 100%;
    padding: 1.25rem 0.7rem;

    > p {
      max-width: 100%;
      white-space: nowrap;
    }
  }

  > .hours {
    max-width: 100%;
    padding: 0.9rem 0.65rem;

    > p {
      max-width: 100%;
      margin-top: 1.9rem;
    }
  }

  p {
    margin: 1.1rem -1rem 1.1rem 0;
    font-size: 0.97rem;
    line-height: 1.1;
  }

  h2 {
    color: ${colors.primary};
    font-size: 0.9rem;
    font-family: ${fonts.bold};
  }

  ${bp("mobile")`
    order: 2;
    padding-top: 1.5rem;
    
    .call-us {
      padding-left: 0;
    }

    .hours {
      padding-left: 0;
    }

    h2 {
      font-size 1.4rem;
    }

    p {
      font-size: 1.4rem;
    }
  `}

  ${bp("ipad")`
    grid-column: 3 / 5;
    >.call-us {
      padding-top: 0.6rem;
    }
  `}
`
export default Footer
