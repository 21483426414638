import React, { useRef, useEffect, useState } from "react"
interface animiProps {
  fade?: "top" | "bottom"
  width?: "full-width"
  speed?: number // 0.5 mean 50%
}

const FadeInSection: React.FC<animiProps> = ({ children, speed }) => {
  const [isVisible, setVisible] = useState(false)
  const domRef = useRef<HTMLDivElement>(null)

  const node = domRef?.current // DOM Ref
  useEffect(() => {
    const hasIOSupport = !!window.IntersectionObserver
    if (!hasIOSupport || !node) return

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          setVisible(entry.isIntersecting)
        })
      },
      { threshold: speed }
    )

    observer.observe(node)
  }, [node])
  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {children}
    </div>
  )
}

export default FadeInSection
